import React from 'react';
import { useStyles } from './style';
import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { useAuthContext } from '../../context/Auth';

const status = [
	{ value: 'open', label: 'Aberto' },
	{ value: 'pending', label: 'Pendente' },
	{ value: 'closed', label: 'Fechado' }
]

const TicketFilters = ({ filters, handleChangeFilters, resetFilters, fetchFilters }) => {
	const classes = useStyles();

	const { users, queues, connections, tags } = useAuthContext();

	return (
		<Box
			className={classes.container}
		>
			<div className={classes.filterContainer}>
				<Typography className={classes.filtersTitle}>
					Filtros
				</Typography>

				<Grid
					container
					className={classes.gridContainer}
				>
					<Grid xs={12} sm={12} md={12} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								value={filters.text}
								name="text"
								label="Texto na mensagem"
								variant="outlined"
								size="medium"
								type="text"
								InputLabelProps={{ shrink: true }}
								onChange={handleChangeFilters}
							/>
						</FormControl>
					</Grid>
				

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<TextField
							value={filters.createdAtStart}
							name="createdAtStart"
							label="Data de criação inicial"
							variant="outlined"
							size="medium"
							type="date"
							InputLabelProps={{ shrink: true }}
							onChange={handleChangeFilters}
						/>
					</Grid>
					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<TextField
							value={filters.createdAtEnd}
							name="createdAtEnd"
							label="Data de criação final"
							variant="outlined"
							size="medium"
							type="date"
							InputLabelProps={{ shrink: true }}
							onChange={handleChangeFilters}
						/>
					</Grid>
					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<TextField
							value={filters.finishedAtStart}
							name="finishedAtStart"
							label="Data de finalização inicial"
							variant="outlined"
							size="medium"
							type="date"
							InputLabelProps={{ shrink: true }}
							onChange={handleChangeFilters}
						/>
					</Grid>
					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<TextField
							value={filters.finishedAtEnd}
							name="finishedAtEnd"
							label="Data de finalização final"
							variant="outlined"
							size="medium"
							type="date"
							InputLabelProps={{ shrink: true }}
							onChange={handleChangeFilters}
						/>
					</Grid>

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<FormControl fullWidth>
							<InputLabel
								style={{
									fontSize: "0.8em",
									marginTop: -13,
									marginLeft: 14,
								}}
							>
								Filtro por Usuários
							</InputLabel>
							<Select
								value={filters.users}
								name="users"
								variant="outlined"
								size="medium"
								multiple
								InputLabelProps={{ shrink: true }}
								onChange={handleChangeFilters}
								renderValue={(selected) => {
									return users
										.filter((user) => selected.includes(user.id))
										.map((user) => user.name)
										.join(", ");
								}}
							>
								{users.map((user) => (
									<MenuItem key={user.id} value={user.id}>
										<Checkbox
											size="small"
											color="primary"
											checked={filters.users.includes(user.id)}
										/>
										{user.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<FormControl fullWidth>
							<InputLabel
								style={{
									fontSize: "0.8em",
									marginTop: -13,
									marginLeft: 14,
								}}
							>
								Filtro por Conexões
							</InputLabel>
							<Select
								value={filters.connections}
								name="connections"
								variant="outlined"
								size="medium"
								InputLabelProps={{ shrink: true }}
								onChange={handleChangeFilters}
								multiple
								renderValue={(selected) => {
									return connections
										.filter((connection) => selected.includes(connection.id))
										.map((connection) => connection.name)
										.join(", ");
								}}
							>
								{connections.map((connection) => (
									<MenuItem key={connection.id} value={connection.id}>
										<Checkbox
											size="small"
											color="primary"
											checked={filters.connections.includes(connection.id)}
										/>
										{connection.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<FormControl fullWidth>
							<InputLabel
								style={{
									fontSize: "0.8em",
									marginTop: -13,
									marginLeft: 14,
								}}
							>
								Filtro por Status
							</InputLabel>
							<Select
								value={filters.status}
								name="status"
								variant="outlined"
								size="medium"
								multiple
								InputLabelProps={{ shrink: true }}
								onChange={handleChangeFilters}
								renderValue={(selected) => {
									return status
										.filter((status) => selected.includes(status.value))
										.map((status) => status.label)
										.join(", ");
								}}
							>
								{status.map((status) => (
									<MenuItem key={status.value} value={status.value}>
										<Checkbox
											size="small"
											color="primary"
											checked={filters.status.includes(status.value)}
										/>
										{status.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<FormControl fullWidth>
							<InputLabel
								style={{
									fontSize: "0.8em",
									marginTop: -13,
									marginLeft: 14,
								}}
							>
								Filtro por Tags
							</InputLabel>
							<Select
								value={filters.tags}
								name="tags"
								variant="outlined"
								size="medium"
								InputLabelProps={{ shrink: true }}
								onChange={handleChangeFilters}
								multiple
								renderValue={(selected) => {
									return tags
										.filter((tag) => selected.includes(tag.id))
										.map((tag) => tag.name)
										.join(", ");
								}}
							>
								{tags.map((tag) => (
									<MenuItem key={tag.id} value={tag.id}>
										<Checkbox
											size="small"
											color="primary"
											checked={filters.tags.includes(tag.id)}
										/>
										{tag.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<FormControl fullWidth>
							<InputLabel
								style={{
									fontSize: "0.8em",
									marginTop: -13,
									marginLeft: 14,
								}}
							>
								Filas
							</InputLabel>
							<Select
								value={filters.queues}
								name="queues"
								multiple
								variant="outlined"
								InputLabelProps={{ shrink: true }}
								onChange={handleChangeFilters}
								renderValue={(selected) => {
									return queues
										.filter((queue) => selected.includes(queue.id))
										.map((queue) => queue.name)
										.join(", ");
								}}
							>
								{queues.map((queue) => (
									<MenuItem key={queue.id} value={queue.id}>
										<Checkbox
											size="small"
											color="primary"
											checked={filters.queues.includes(queue.id)}
										/>
										{queue.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
				

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<TextField
							value={filters.nameOfContact}
							name="nameOfContact"
							label="Nome do contato"
							variant="outlined"
							size="medium"
							type="text"
							InputLabelProps={{ shrink: true }}
							onChange={handleChangeFilters}
						/>
					</Grid>

					<Grid item md={2} sm={4} xs={6} className={classes.gridItem}>
						<TextField
							value={filters.number}
							name="number"
							label="Numero do contato"
							variant="outlined"
							size="medium"
							type="number"
							InputLabelProps={{ shrink: true }}
							onChange={handleChangeFilters}
						/>
					</Grid>

					<Grid 
						item 
						md={2} 
						sm={4} 
						xs={6} 
						className={classes.gridItem}
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							rowGap: 10
						}}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={resetFilters}
							className={classes.clearFiltersBtn}
						>
							Limpar Filtros
						</Button>

						<Button
							variant="contained"
							color="primary"
							onClick={() => fetchFilters(true)}
							className={classes.searchBtn}
						>
							Procurar
						</Button>
					</Grid>
				</Grid>
			</div>
			
			<br />

		</Box>
	)
}

export default TicketFilters;
