import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { useAuthContext } from '../../context/Auth';
import { useSettingsContext } from '../../context/Settings';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  btnWrapper: {
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },

  textCenter: {
    backgroundColor: 'red',
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  number: Yup.string().min(8, 'Too Short!').max(50, 'Too Long!'),
  email: Yup.string().email('Invalid email'),
});

export function ContactForm({ initialContact, onSave, onCancel }) {
  const classes = useStyles();
  const { getSettingValue } = useSettingsContext();
  const { user } = useAuthContext();

  const [contact, setContact] = useState(initialContact);

  const isUser = user.profile === 'user';
  const isNumberCensored = getSettingValue('contactCensorship') === 'enabled' && isUser;

  const handleSaveContact = async values => {
    try {
      if (contact.id) {
        await api.put(`/contacts/${contact.id}`, values);
      } else {
        const { data } = await api.post('/contacts', values);
        if (onSave) {
          onSave(data);
        }
      }
      toast.success(i18n.t('contactModal.success'));
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    setContact(initialContact);
  }, [initialContact]);

  return (
    <Formik
      initialValues={contact}
      enableReinitialize={true}
      validationSchema={ContactSchema}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          handleSaveContact(values);
          actions.setSubmitting(false);
        }, 400);
      }}
    >
      {({ values, errors, touched, isSubmitting }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Field
                as={TextField}
                label={i18n.t('contactModal.form.name')}
                name="name"
                autoFocus
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                variant="outlined"
                margin="dense"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            {isNumberCensored ? null : (
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  label={i18n.t('contactModal.form.number')}
                  name="number"
                  error={touched.number && Boolean(errors.number)}
                  helperText={touched.number && errors.number}
                  placeholder="5513912344321"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={10}>
              <Field
                as={TextField}
                label={i18n.t('contactModal.form.email')}
                name="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                placeholder="Email address"
                fullWidth
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={10} spacing={1}>
              <Grid container spacing={1}>
                <Grid xs={5} item>
                  <Button onClick={onCancel} color="secondary" disabled={isSubmitting} variant="outlined" fullWidth>
                    {i18n.t('contactModal.buttons.cancel')}
                  </Button>
                </Grid>
                <Grid classes={classes.textCenter} xs={5} item>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                    fullWidth
                  >
                    {contact.id
                      ? `${i18n.t('contactModal.buttons.okEdit')}`
                      : `${i18n.t('contactModal.buttons.okAdd')}`}
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
