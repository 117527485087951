import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  input: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#adb5bd",
      },
      "&:hover fieldset": {
        borderColor: "#adb5bd",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#adb5bd",
      },
    },
    "& .MuiInputBase-input": {
      color: "black",
    },
    "& .MuiFormLabel-root": {
      color: "black",
    },
  },
  formStyle: {
    position: "sticky",
    top: 0,
    background: "inherit",
    zIndex: 1,
  },
}));

export default useStyles;
