import React from 'react';

import { Typography } from '@mui/material';

import { i18n } from '../../../../translate/i18n';
import { useStyles } from './styles';

function QuotedMedia({ mediaType, mediaUrl, body }) {
  const classes = useStyles();

  if (!mediaUrl) return null;

  switch(mediaType) {
    case 'image':
      return <img className={classes.media} alt={body} src={mediaUrl} />
    case 'video':
      return <video className={classes.media} src={mediaUrl} />;
    case 'audio':
      return (
        <Typography>
          {i18n.t('messagesList.quotedAudio.message')}
        </Typography>
      )
    default:
      return null
  }
}

export default QuotedMedia;
