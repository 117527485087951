import React, { useEffect, useState } from 'react';

import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import NotificationsPopOver from '../components/NotificationsPopOver';
import AuthProvider from '../context/Auth';
import OnlineProvider from '../context/Online';
import { SettingsProvider } from '../context/Settings';
import { TagsProvider } from '../context/Tags';
import { TicketsContextProvider } from '../context/Tickets/TicketsContext';
import { WhatsAppsProvider } from '../context/WhatsApp/WhatsAppsContext';
import LoggedInLayout from '../layout';
import Annoucements from '../pages/Annoucements';
import CampaignReport from '../pages/CampaignReport';
import Campaigns from '../pages/Campaigns';
import CampaignsConfig from '../pages/CampaignsConfig';
import Chat from '../pages/Chat';
import Connections from '../pages/Connections/';
import ContactListItems from '../pages/ContactListItems/';
import ContactLists from '../pages/ContactLists/';
import Contacts from '../pages/Contacts/';
import Dashboard from '../pages/Dashboard/';
import Financeiro from '../pages/Financeiro/';
import Helps from '../pages/Helps/';
import Kanban from '../pages/Kanban';
import Login from '../pages/Login/';
import MessageBlocks from "../pages/MessageBlocks";
import MessagesAPI from '../pages/MessagesAPI/';
import Queues from '../pages/Queues/';
import QuickMessages from '../pages/QuickMessages/';
import Reports from "../pages/Reports";
import Schedules from '../pages/Schedules';
import SettingsCustom from '../pages/SettingsCustom/';
import Signup from '../pages/Signup/';
import Subscription from '../pages/Subscription/';
import Tags from '../pages/Tags/';
import TicketResponsiveContainer from '../pages/TicketResponsiveContainer';
import Users from '../pages/Users';
import Route from './Route';

function Routes() {
  const [showCampaigns, setShowCampaigns] = useState(false);

  useEffect(() => {
    const cshow = localStorage.getItem('cshow');
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <SettingsProvider>
          <TicketsContextProvider>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              {/* <Route exact path="/create-company" component={Companies} /> */}
              <TagsProvider>
                <OnlineProvider>
                  <WhatsAppsProvider>
                    <LoggedInLayout>
                      <NotificationsPopOver />
                      <Route exact path="/" component={Dashboard} isPrivate />
                      <Route exact path="/tickets/:ticketId?" component={TicketResponsiveContainer} isPrivate />
                      <Route exact path="/connections" component={Connections} isPrivate />
                      <Route exact path="/quick-messages" component={QuickMessages} isPrivate />
                      <Route exact path="/schedules" component={Schedules} isPrivate />
                      <Route exact path="/message-blocks" component={MessageBlocks} isPrivate />
                      <Route exact path="/tags" component={Tags} isPrivate />
                      <Route exact path="/contacts" component={Contacts} isPrivate />
                      <Route exact path="/helps" component={Helps} isPrivate />
                      <Route exact path="/users" component={Users} isPrivate />
                      <Route exact path="/messages-api" component={MessagesAPI} isPrivate />
                      <Route exact path="/settings" component={SettingsCustom} isPrivate />
                      <Route exact path="/financeiro" component={Financeiro} isPrivate />
                      <Route exact path="/queues" component={Queues} isPrivate />
                      <Route exact path="/announcements" component={Annoucements} isPrivate />
                      <Route exact path="/subscription" component={Subscription} isPrivate />
                      <Route exact path="/reports" component={Reports} isPrivate />
                      <Route exact path="/kanban" component={Kanban} isPrivate />
                      <Route exact path="/chats/:id?" component={Chat} isPrivate />

                      {showCampaigns && (
                        <>
                          <Route exact path="/contact-lists" component={ContactLists} isPrivate />
                          <Route
                            exact
                            path="/contact-lists/:contactListId/contacts"
                            component={ContactListItems}
                            isPrivate
                          />
                          <Route exact path="/campaigns" component={Campaigns} isPrivate />
                          <Route exact path="/campaign/:campaignId/report" component={CampaignReport} isPrivate />
                          <Route exact path="/campaigns-config" component={CampaignsConfig} isPrivate />
                        </>
                      )}
                    </LoggedInLayout>
                  </WhatsAppsProvider>
                </OnlineProvider>
              </TagsProvider>
            </Switch>
            <ToastContainer autoClose={3000} />
          </TicketsContextProvider>
        </SettingsProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Routes;
