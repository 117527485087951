import { green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  messagesListWrapper: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    minWidth: 300,
    minHeight: 200,
    fontFamily: 'Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif',
    scrollBehavior: 'smooth',
  },

  messagesList: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '20px 20px 20px 20px',
    overflowY: 'scroll',
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    opacity: '70%',
    top: 0,
    left: '50%',
    alignSelf: 'center',
    marginTop: 64,
  },

  message: {
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: 'auto',
    display: 'block',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 4,
    paddingBottom: 4,

    '&:hover #messageActionsButton': {
      display: 'flex',
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },

  messageRight: {
    marginLeft: 20,
    backgroundColor: '#0084ff',
    color: '#fff',
    alignSelf: 'flex-end',
    transition: '1s',
  },

  messageLeft: {
    marginRight: 20,
    backgroundColor: '#f0f0f0',
    color: '#333',
    alignSelf: 'flex-start',
  },

  mediaContainer: {
    paddingBottom: '20px',
  },

  audioContainer: {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,

    '& $timestampFromMe': {
      color: '#333',
    }
  },

  imageMediaContainer: {
    '& > div:first-of-type': {
      height: '200px',
    }
  },

  messageActionsButton: {
    display: 'none',
    position: 'relative',
    color: '#999',
    zIndex: 1,
    backgroundColor: 'inherit',
    opacity: '90%',
    '&:hover, &.Mui-focusVisible': { backgroundColor: 'inherit' },
  },

  messageContactName: {
    display: 'flex',
    color: '#6bcbef',
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: 'break-word',
    padding: '3px 80px 6px 6px',
    fontSize: 'medium',
  },

  textContentItemDeleted: {
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.36)',
    overflowWrap: 'break-word',
    padding: '3px 80px 6px 6px',
  },

  videoMedia: {
    objectFit: 'cover',
    height: '200px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: 'absolute',
    bottom: 0,
    right: 5,
  },

  timestampFromMe: {
    color: '#fff',
  },

  dailyTimestamp: {
    alignItems: 'center',
    textAlign: 'center',
    alignSelf: 'center',
    width: '110px',
    backgroundColor: '#fff',
    margin: '10px',
    // borderRadius: "10px",
  },

  dailyTimestampText: {
    color: '#666',
    padding: 8,
    alignSelf: 'center',
    marginLeft: '0px',
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: 'middle',
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: 'middle',
    marginLeft: 4,
  },

  downloadMedia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'inherit',
    padding: 10,
  },
  imageLocation: {
    position: 'relative',
    width: '100%',
    height: 80,
    borderRadius: 5,
  },
  messageAvatarWrapper: {
    display: 'flex' /* Usa Flexbox */,
    alignItems: 'flex-end',
    marginBottom: 4,
  },
  comment: {
    margin: '1px auto',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#eeeeee88',
    padding: '4px 4px 4px 4px',
    borderRadius: '5px',
    boxShadow: '0 0 5px 0px rgb(0, 0, 0, 0.1)',
    marginTop: '10px',
  },
	reactionMedia: {
		objectFit: 'cover',
		height: '200px',
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
}));
