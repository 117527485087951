import React, { useEffect, useState } from "react";

import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core/styles";
import ModalImage from "react-modal-image";

const useStyles = makeStyles(theme => ({
	imageMedia: {
		objectFit: "cover",
		height: 200,
		borderTopLeftRadius: 8,
		borderTopRightRadius: 8,
		borderBottomLeftRadius: 8,
		borderBottomRightRadius: 8,
	},
}));

const ModalImageCors = ({ imageUrl, className, onlyModalImage }) => {
	const classes = useStyles();
	const [isImageValid, setIsImageValid] = useState(false);

	useEffect(() => {
		setIsImageValid(false);
	}, [imageUrl]);

	useEffect(() => {
		const verifyImage = async () => {
			try {
				const fetchImage = await fetch(imageUrl);

				if (!fetchImage.ok || !fetchImage.headers.get('Content-Type').includes('image')) {
					setIsImageValid(false);
				} else {
					setIsImageValid(true);
				}
			} catch (error) {
				console.error('Failed to fetch image:', error);
				setIsImageValid(false);
			}
		};

		verifyImage();
	}, [imageUrl]);

	return (
		<>
			{(isImageValid || onlyModalImage) ? (
				<ModalImage
					className={className ? className : classes.imageMedia}
					smallSrcSet={imageUrl}
					medium={imageUrl}
					large={imageUrl}
					alt="image"
				/>
			): (
				<Avatar src={''} className={className ? className : classes.imageMedia} />
			)}
		</>
	);
};

export default ModalImageCors;
