import React, { useEffect, useRef, useState } from "react";

import { Paper, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isArray, isString } from 'lodash';
import { toast } from 'react-toastify';

import toastError from "../../errors/toastError";
import { getRandomHexColor } from '../../helpers/getRandomHexColor';
import api from "../../services/api";
import ContactTag from '../ContactTag';
import { useStyles } from './styles';

function TagsContainer({ ticket }) {
  const classes = useStyles();
  const isMounted = useRef(true);

  const [tags, setTags] = useState([]);
  const [selected, setSelected] = useState([]);

  const createTag = async data => {
    try {
      const tagData = { ...data, color: getRandomHexColor() };
      const { data: responseData } = await api.post(`/tags`, tagData);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const syncTags = async data => {
    try {
      const { data: responseData } = await api.post(`/tags/sync`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = async (value, reason) => {
    let optionsChanged = [];
    const currentTags = tags.map(tag => tag?.name.toLowerCase());
    if (reason === 'create-option') {
      if (isArray(value)) {
        for (let item of value) {
          if (isString(item) && currentTags.includes(item.toLowerCase())) {
            toast.error(`A Tag [${item}] já existe!`);
          } else if (isString(item)) {
            const newTag = await createTag({ name: item });
            optionsChanged.push(newTag);
          } else {
            optionsChanged.push(item);
          }
        }
      }
      await loadTags();
    } else {
      optionsChanged = value;
    }
    setSelected(optionsChanged);
    await syncTags({ ticketId: ticket.id, tags: optionsChanged });
  };

  const handleDelete = async (event, tag) => {
    if (event.target.tagName === 'svg' || event.target.tagName === 'path') {
      // Prepare os dados para a API (remova as tags do ticket, se necessário)
      const { id, tags } = ticket;
      const data = {
        ticketId: id,
        tags: tags.filter(_tag => _tag.id !== tag.id),
      };
      setSelected(data.tags);

      try {
        const { data: responseData } = await api.post(`/tags/sync`, data);
        return responseData;
      } catch (error) {
        console.log('TagsContainer handleDelete error:', error);
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      loadTags().then(() => {
        if (Array.isArray(ticket.tags)) {
          setSelected(ticket.tags);
        } else {
          setSelected([]);
        }
      });
    }
  }, [ticket]);

  return (
    <Paper square elevation={0} className={classes.tagContainer}>
      <Autocomplete
        multiple
        size="small"
        options={tags}
        value={selected}
        freeSolo
        onChange={(e, v, r) => onChange(v, r)}
        getOptionLabel={option => option.name}
        renderTags={(value, getTagProps) =>
          value.map((tag, index) => (
            <ContactTag
              {...getTagProps({ index })}
              tag={tag}
              handleDelete={event => handleDelete(event, tag)}
              icon
            />
          ))
        }
        renderInput={params => <TextField {...params} variant="outlined" placeholder="Tags" />}
        PaperComponent={({ children }) => <Paper elevation={10} style={{ width: 400, marginLeft: 12 }}>{children}</Paper>}
      />
    </Paper>
  );
}

export default TagsContainer;
