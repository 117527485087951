import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";

import { i18n } from "../../translate/i18n";

const ErrorModal = ({ open, onClose, message }) => {

  const handleClose = () => {
    onClose();
  };

  return (
    <div >
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          Contato em atendimento
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle1" gutterBottom>
            O contato já está sendo atendido por: <b>{message?.user?.name}</b>
          </Typography>
          {message?.queue && (
            <Typography variant="subtitle1" gutterBottom>
              Na fila: <b>{message?.queue?.name}</b>
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="outlined"
          >
            {i18n.t("Fechar")}
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
};

export default ErrorModal;
