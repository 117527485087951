import React, { useEffect, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-toastify/dist/ReactToastify.css';

import ColorModeContext from './layout/themeContext';
import Routes from './routes';

const queryClient = new QueryClient();

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const preferredTheme = window.localStorage.getItem('preferredTheme');

  const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? 'dark' : 'light');
  const [locale, setLocale] = useState();


  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = createTheme(
    {
      scrollbarStyles: {
        '&::-webkit-scrollbar': {
          display: 'inherit',
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          backgroundColor: '#F0F8FF',
        },
      },
      scrollbarStylesSoft: {
        '&::-webkit-scrollbar': {
          width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#F3F3F3',
        },
      },
      palette: {
        type: mode,
        primary: { main: '#0032C0' },
        textPrimary: '#0032C0',
        borderPrimary: '#0032C0',
        dark: { main:'#333333' },
        light: { main:'#F3F3F3' },
        tabHeaderBackground: '#EEE',
        optionsBackground: '#fff',
        fancyBackground: '#fff',
        total: '#fff',
        messageIcons: 'grey',
        inputBackground: '#FFFFFF',
        barraSuperior: '#002f70',
      },
      overrides: {
        MuiButton: {
          contained: {
            boxShadow: 'unset',
          }
        }
      },
      mode,
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem('i18nextLng');
    const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === 'ptBR') {
      setLocale(ptBR);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('preferredTheme', mode);
  }, [mode]);

  return (
    <ColorModeContext.Provider value={{ colorMode }}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Routes />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
