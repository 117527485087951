import { green } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    height: 80,
    borderRadius: "8px",
    padding: '0 24px 0 8px',
    backgroundColor: '#eee',
  },

  selectedTicket: {
    backgroundColor: '#fff !important',
    boxShadow: "0px 0px 0px 0px #fff !important",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactInfoWrapper: {
    display: "flex",
    flexDirection: 'column',
    justifyContent: "space-between",
    gap: '8px',
  },

  titleName: {
    '@media only screen and (min-width: 960px)': {
      maxWidth: '12ch',
    },

    '@media only screen and (min-width: 1024px)': {
      maxWidth: '16ch',
    },

    '@media only screen and (min-width: 1280px)': {
      maxWidth: '80%'
    }
  },

  contactWrapper: {
    padding: '4px 16px',
  },

  lastMessageTime: {
    fontSize: 12
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: "50%",
    marginTop: "1px"
  },

  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    padding: '12px 0',
    height: '100%',
  },

  resolvedText: {
    padding: '2px 4px',
    color: '#fff',
    backgroundColor: '#01a21f',
    fontSize: '12px',
    borderRadius: '4px',
  },

  badgeStyle: {
    backgroundColor: green[500],
    color: "#fff",
    top: '-10px',
    right: '10px'
  },

  acceptButton: {
    position: "absolute",
    right: "108px",
  },

  ticketQueueColor: {
    flex: "none",
    width: "12px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },

  ticketTooltipQueueColorAdmin: {
    borderRadius: "8px",
    padding: '0 8px'
  },

  ticketTooltipQueueColorUser: {
    width: "18px",
    height: "18px",
    minWidth: "18px",
    borderRadius: "50%",
    padding: 'unset',
  },

  ticketTooltipConnectionColor: {
    width: "18px",
    minWidth: "18px",
    height: "18px",
    borderRadius: "50%",
  },

  ticketInfo: {
    position: "relative",
    top: 0
  },

  ticketInfo1: {
    position: "relative",
    right: 0,
    width: '100%',
  },

  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3,
      fontSize: 10,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },

  bgBlack: {
    backgroundColor: "#000 !important",
  },

  tooltipsWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: "relative",
    gap: '4px',
    width: '100%',
  },

  ticketChannelIconWrapper: {
    position: 'absolute',
    left: '70px',
    top: '50px',
  },

  tagsWrapper: {
    left: "160px",
    top: "-70px !important",
  },

  ticketUnread: {
    backgroundColor: '#00d2003d',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
  },
}));
