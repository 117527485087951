import React, { useCallback, useContext, useState } from "react";

import {
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Pencil, Trash2 } from 'lucide-react';
import { useHistory, useParams } from "react-router-dom";

import ConfirmationModal from "../../components/ConfirmationModal";
import { AuthContext } from "../../context/Auth";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    height: "calc(100% - 58px)",
    overflow: "hidden",
    borderRadius: 0,
  },

  chatList: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  listItem: {
    cursor: "pointer",
    backgroundColor: '#eee',
  },

  selectedListItem: {
    borderLeft: '6px solid #002d6e',
    backgroundColor: '#7f7f7f',

    '& item-icon': {
      color: 'black',
    }
  },

  inline: {
    color: "white"
  },
}));

function ChatList({
  chats,
  handleSelectChat,
  handleDeleteChat,
  handleEditChat,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { datetimeToClient } = useDate();
  const { id } = useParams();
  const { user } = useContext(AuthContext);

  const [confirmationModal, setConfirmModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState({});

  const goToMessages = async (chat) => {
    if (unreadMessages(chat) > 0) {
      try {
        await api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) { }
    }

    if (id !== chat.uuid) {
      history.push(`/chats/${chat.uuid}`);
      handleSelectChat(chat);
    }
  };

  const handleDelete = () => {
    handleDeleteChat(selectedChat);
  };

  const unreadMessages = (chat) => {
    const currentUser = chat.users.find((u) => u.userId === user.id);
    return currentUser.unreads;
  };

  const getPrimaryText = (chat) => {
    const mainText = chat.title;
    const unreads = unreadMessages(chat);
    return (
      <>
        {mainText}
        {unreads > 0 && (
          <Chip
            size="small"
            style={{ marginLeft: 5 }}
            label={unreads}
            color="secondary"
          />
        )}
      </>
    );
  };

  const getSecondaryText = (chat) => {
    return chat.lastMessage !== ""
      ? `${datetimeToClient(chat.updatedAt)}: ${chat.lastMessage}`
      : "";
  };

  const getIsChatSelected = useCallback((chat) => {
    return chat.uuid === id
  }, [id]);

  return (
    <>
      <ConfirmationModal
        title={"Excluir Conversa"}
        open={confirmationModal}
        onClose={setConfirmModalOpen}
        onConfirm={handleDelete}
      >
        Esta ação não pode ser revertida, confirmar?
      </ConfirmationModal>
      <div className={classes.mainContainer}>
        <div className={classes.chatList}>
          <List>
            {Array.isArray(chats) &&
              chats.length > 0 &&
              chats.map((chat, key) => (
                <ListItem
                  onClick={() => goToMessages(chat)}
                  key={key}
                  className={clsx(
                    classes.listItem,
                    { [classes.selectedListItem]: getIsChatSelected(chat) },
                  )}
                  button
                >
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          noWrap
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {getPrimaryText(chat)}
                        </Typography>
                      </>
                    }
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {getSecondaryText(chat)}
                        </Typography>
                      </>
                    }
                  />

                  {chat.ownerId === user.id && (
                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          goToMessages(chat).then(() => {
                            handleEditChat(chat);
                          });
                        }}
                        edge="end"
                        aria-label="delete"
                        size="small"
                        style={{ marginRight: 5 }}
                        classes={{ root: 'item-icon' }}
                      >
                          <Pencil strokeWidth="1.5px" size={24} />
                      </IconButton>

                      <IconButton
                        onClick={() => {
                          setSelectedChat(chat);
                          setConfirmModalOpen(true);
                        }}
                        edge="end"
                        aria-label="delete"
                        size="small"
                        classes={{ root: 'item-icon' }}
                      >
                        <Trash2 strokeWidth="1.5px" size={24} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
          </List>
        </div>
      </div>
    </>
  );
}

export default ChatList;
