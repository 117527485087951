import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "16px",
    paddingBottom: "8px",
    width: "100%",
		flexDirection: "column",
  },

  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
		flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "20px",
    padding: "8px 0 0 8px",
    width: "100%",
		border: "1px solid #ccc",
  },

	gridContainer: {
		justifyContent: "center",
		rowGap: "16px",
	},

  filtersTitle: {
    fontWeight: "bold",
    opacity: "0.8",
  },

  searchBtn: {
    padding: "8px 24px",
  },

  clearFiltersBtn: {
    padding: "8px 24px",
  },

  gridItem: {
    width: "100%",
    padding: "8px",
  },

	selectContainer: {
    width: "100%",
    textAlign: "left",
  },
}));
