import toastError from '../errors/toastError';
import api from './api';

async function getTicketMessages({ ticketId, pageNumber = 1 }) {
  if (ticketId === undefined) return;

  try {
    const { data } = await api.get('/messages/' + ticketId, {
      params: { pageNumber },
    });

    const messages = [...data.messages, ...data.notes.map(note => ({ mediaType: 'comment', ...note }))].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    return { messages, hasMore: data.hasMore };
  } catch (err) {
    toastError(err);
  }
}

export default getTicketMessages;
