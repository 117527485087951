import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},

  iconButton: {
    backgroundColor: '#eeeeee3d',
    borderRadius: '4px',
    padding: '4px',

    '&:hover': {
      opacity: 0.7,
    }
  },

  checkButton: {
    backgroundColor: green[500],
    color: 'white',

    '&:hover': {
      backgroundColor: green[500],
    }
  },
}));
