import React from 'react';

import { Typography } from '@mui/material';

import { i18n } from "../../../../translate/i18n";


function ViewOnce({ disable }) {
  const LinkComponent = ({ children, ...rest }) => {
    if (disable) return <span>{children}</span>
    return <a {...rest}>{children}</a>
  };

  return (
    <Typography fontStyle="italic" color="#AAA">
      {`${i18n.t("messagesList.viewOnceNotice.message")}`}
      &nbsp;
      <LinkComponent
        target="_blank"
        href="https://faq.whatsapp.com/578442220724722"
        rel="noreferrer"
      >
      {`${i18n.t("messagesList.viewOnceNotice.link")}`}
      </LinkComponent>
    </Typography>
  )
}

export default ViewOnce;
