import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tagContainer: {
    padding: '0 12px',
    position: 'relative',
    top: 0,

    [theme.breakpoints.down('md')]: {
      paddingTop: '8px',
    },
  },
}));
