import axios from "axios";

import { envConfig } from '../configs';

const api = axios.create({
	baseURL: envConfig.backendUrl,
	withCredentials: true,
});

export const openApi = axios.create({
	baseURL: envConfig.backendUrl
});

export default api;
