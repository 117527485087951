import React, { useContext, useEffect, useRef, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import SearchIcon from "@material-ui/icons/SearchTwoTone";
import useStyles from "./styles";
import Avatar from "@material-ui/core/Avatar";

const ForwardModal = ({ open, onClose, message }) => {
	const classes = useStyles();

	const [whatsapps, setWhatsapps] = useState([]);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const [selectedQueue, setSelectedQueue] = useState("");
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const { user } = useContext(AuthContext);
	const scrollRef = useRef(null);

  const clearStates = () => {
    setContacts([]);
    setSearchQuery("");
    setHasMore(null);
    setPageNumber(null);
    setLoading(false);
    setSelectedContacts([]);
    setSelectedQueue();
		setSelectedWhatsapp();
  };

  useEffect(() => {
    !open && clearStates();
  }, [open]);

  useEffect(() => {
    const getContacts = async () => {
      try {
        const { data } = await api.get("/contacts-search", {
					params: { searchParam: '', pageNumber: 1},
				});
        setContacts(data.contacts);
        setHasMore(data.hasMore);
				setPageNumber(1);
      } catch {
        console.log("Erro ao pegar chats");
      }
    };
  

		if (open) {
			getContacts();
			setSearchQuery("");
			setSelectedQueue(message?.ticket?.queueId);
		}
  }, [open, message, user]);

  const search = async (e) => {
    e.preventDefault();
    try {
      const { data } = await api.get('/contacts-search', {
				params: { searchParam: searchQuery, pageNumber: 1 },
			});
      setContacts(data.contacts);
      setHasMore(data.hasMore);
			setPageNumber(1);
    } catch (err) {
      toastError(err);
    }
  };

	useEffect(() => {
		const getWhatsappsByQueues = async () => {
			const { data } = await api.get("/whatsapp-by-queues", {
				params: { queueIds: JSON.stringify([selectedQueue]) },
			});

			setWhatsapps(data);
		};

		if (selectedQueue) {
			getWhatsappsByQueues();
		}
	}, [selectedQueue]);

  const fetchMoreContacts = async (pageNumber) => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/contacts-search`, {
					params: { searchParam: searchQuery, pageNumber },
				});
      setContacts((prevContacts) => [...prevContacts, ...data.contacts]);
      setHasMore(data.hasMore);
      setPageNumber(pageNumber);
    } catch (err) {
      toastError(err);
    } finally {
			setLoading(false);
		}
  };

	useEffect(() => {
		if (loading || !hasMore) return;

		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting) {
					fetchMoreContacts(pageNumber + 1);
				}
			},
			{ threshold: 1 }
		);

		if (scrollRef.current) {
			observer.observe(scrollRef.current);
		}

		return () => observer.disconnect();
	
	}, [scrollRef.current, pageNumber, loading, hasMore])

  const toggleContact = (contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        return prevSelectedContacts.filter((id) => id !== contactId);
      } else {
        return [...prevSelectedContacts, contactId];
      }
    });
  };

  const forwardMessage = async () => {
    setLoading(true);
    try {
      await api.post(`/forward`, {
        messageId: message.id,
        queueId: selectedQueue,
        contactIds: selectedContacts,
				whatsappId: selectedWhatsapp,
      });
      toast.success("Mensagens enviadas");
    } catch (err) {
      toastError(err);
    } finally {
			setLoading(false);
			onClose();
		}
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
			<DialogTitle>
				<Typography className={classes.title} variant="h5" component="h6">
					Compartilhar mensagem para
				</Typography>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={(e) => search(e)}>
					<TextField
						variant="outlined"
						label="Pesquise seus contatos"
						value={searchQuery}
						style={{ width: "100%" }}
						onChange={(e) => setSearchQuery(e.target.value)}
						className={classes.input}
						InputProps={{
							endAdornment: (
								<IconButton type="submit">
									<SearchIcon />
								</IconButton>
							),
						}}
					/>
				</form>

				<List
					style={{ height: "50vh", overflow: "auto" }}
				>
					{contacts.map((contact, index) => (
						<ListItem key={contact.id}>
							<Checkbox
								ref={index === contacts.length - 1 ? scrollRef : null}
								checked={selectedContacts.includes(contact.id)}
								onChange={() => toggleContact(contact.id)}
							/>
							<Avatar 
								src={contact.profilePicUrl}
								alt={contact.name}
								style={{ marginRight: "10px" }}
							/>
							<ListItemText primary={contact.name} />
						</ListItem>
					))}
				</List>


				<Typography htmlFor="queue-select">Selecione uma fila</Typography>
				<Select
					fullWidth
					displayEmpty
					variant="outlined"
					value={selectedQueue}
					id="queue-select"
					onChange={(e) => {
						setSelectedQueue(e.target.value);
					}}
					style={{ marginBottom: "10px" }}
				>
					{user &&
						user.queues.map((whats, key) => (
							<MenuItem dense key={key} value={whats.id}>
								<ListItemText primary={whats.name} />
							</MenuItem>
						))}
				</Select>

				<Typography htmlFor="whatsapp-select">Selecione um whatsapp</Typography>
				<Select
					fullWidth
					displayEmpty
					variant="outlined"
					value={selectedWhatsapp}
					onChange={(e) => {
						setSelectedWhatsapp(e.target.value);
					}}
					id="whatsapp-select"
				>
					{whatsapps &&
						whatsapps.map((whats, key) => (
							<MenuItem dense key={key} value={whats.id}>
								<ListItemText primary={whats.name} />
							</MenuItem>
						))}
				</Select>

				<Typography variant="caption" style={{ marginTop: "10px" }}>
					OBS: Caso já tenha um ticket aberto com este contato, a mensagem será enviada no ticket existente.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="secondary" onClick={onClose}>
					Fechar
				</Button>
				<Button
					variant="contained"
					color="primary"
					disabled={selectedContacts.length === 0 || !selectedQueue || !selectedWhatsapp || loading}
					onClick={forwardMessage}
				>
					Enviar
				</Button>
			</DialogActions>
    </Dialog>
  );
};

export default ForwardModal;
