import React, { useContext, useEffect, useState } from "react";

import {
  Drawer,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { useLocation } from "react-router-dom";

import BackdropLoading from "../components/BackdropLoading";
import UserModal from "../components/UserModal";
import { useAuthContext } from "../context/Auth";
import toastError from "../errors/toastError";
import { useDate } from "../hooks/useDate";
import ColorModeContext from "../layout/themeContext";
import { socketConnection } from "../services/socket";
import MainListItems from "./MainListItems";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    backgroundColor: theme.palette.fancyBackground,
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    color: "#212121",
    background: "#fff",
  },

  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "4px",
    paddingTop: "8px",
    minHeight: "60px",
    maxHeight: "60px",
    background: "#0032c0",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  menuButton: {
    marginRight: 36,
  },

  menuButtonHidden: {
    display: "none",
  },

  title: {
    flexGrow: 1,
    fontSize: 14,
  },

  drawerPaper: {
    overflowX: "hidden",
    position: "relative",
    backgroundColor: "transparent",
    whiteSpace: "nowrap",
    borderRight: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6.5),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(6.5),
    },

    '& .toolbar-logo': {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0.5s, opacity 0.5s linear',
      width: 0,
      height: 0,
    },

    '& .toolbar-small-logo': {
      visibility: 'visible',
      opacity: 1,
      width: "inherit",
      height: "20px",
      transition: 'visibility 0.5s, opacity 0.5s linear',
    },

    '&:hover': {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      '& .toolbar-logo': {
        visibility: 'visible',
        opacity: 1,
        transition: 'visibility 0.5s, opacity 0.5s linear',
        width: 'inherit',
        height: '48px',
        margin: "0 auto"
      },

      '& .toolbar-small-logo': {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0.5s, opacity 0.5s linear',
        width: 0,
        height: 0,
      },
    }
  },

  appBarSpacer: {
    minHeight: "50px",
  },

  content: {
    flex: 1,
    overflow: "auto",
    ...theme.scrollbarStyles,
  },

  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

function LoggedInLayout({ children }) {
  const classes = useStyles();
  const { dateToClient } = useDate();
  const theme = useTheme();
  const greaterThenSm = useMediaQuery(theme.breakpoints.up("sm"));
  const currentPage = useLocation();
  const { user, handleLogout, loading } = useAuthContext();
  const { colorMode } = useContext(ColorModeContext);

  const [userModalOpen, setUserModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState("permanent");
  const [isExpired, setIsExpired] = useState(localStorage.getItem('isExpired') === "true");
	const [companyDueDate, setCompanyDueDate] = useState(localStorage.getItem('companyDueDate'));

	useEffect(() => {
		setIsExpired(localStorage.getItem('isExpired') === "true")
	}, [localStorage.getItem('isExpired')])

	useEffect(() => {
		setCompanyDueDate(localStorage.getItem('companyDueDate'))
	}, [localStorage.getItem('companyDueDate')])

  if (isExpired && currentPage.pathname !== "/financeiro") {
    const clearBlur = setInterval(() => {
      const layout = document.getElementById("mainLayout");
      if (layout) {

        layout.style.filter = "blur(2.5px)";
        clearInterval(clearBlur);
      }
    }, 100);

  } else {

    const clearBlur = setInterval(() => {
      const layout = document.getElementById("mainLayout");
      if (layout) {
        layout.style.filter = "blur(0px)";
        clearInterval(clearBlur);
      }
    }, 100);
  }

  useEffect(() => {
    if (document.body.offsetWidth < 600) {
      setDrawerVariant("temporary");
    } else {
      setDrawerVariant("permanent");
    }
  }, [drawerOpen]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const toggleColorMode = () => {
    colorMode.toggleColorMode();
  }

  const drawerClose = () => {
    if (document.body.offsetWidth < 600) {
      setDrawerOpen(false);
    }
  };

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <div className={classes.root}>
      <Drawer
        variant={drawerVariant}
        className={classes.drawerPaper}
        classes={{
          paper: classes.drawerPaper
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <img
            src="/assets/logo.png"
            className="toolbar-logo"
            alt="logo"
          />

          <img
            src="/assets/icon-cc.png"
            className="toolbar-small-logo"
            alt="logo"
          />
        </div>

        <MainListItems drawerClose={drawerClose} className={classes.containerWithScroll} />
      </Drawer>
      <UserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />

      <main id="mainLayout" className={classes.content}>
        {/* <div className={classes.appBarSpacer} /> */}

        {children ? children : null}
      </main>

      {isExpired && currentPage.pathname !== "/financeiro" && (

        <div style={{ textAlign: 'center', fontSize: '20px', color: 'red', marginTop: '20px', zIndex: 9999, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: 'white', padding: '20px', borderRadius: '10px', border: '1px solid #ccc' }}>
          Prezado(a) <b>{user.name} - {user?.company?.name},</b> <br />
          Informamos que há uma pendência referente à sua mensalidade. Solicitamos a <br />
          regularização da mesma para evitar possíveis contratempos. Para efetuar o <br />
          pagamento, gentilmente clique no botão abaixo:  <br />
          <a href="https://wa.me/5511952708030?text=Oi">Clique aqui para realizar o pagamento</a>
          <br /> <br />
          Atenciosamente, <br />
          Equipe <b>Chatclipy</b> 📎 <br />
        </div>

      )}

    </div>
  );
};

export default LoggedInLayout;
