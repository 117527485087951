import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

import useAuth from "../../hooks/useAuth";
import api from "../../services/api";

export const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
	const { loading, user, isAuth, handleLogin, handleLogout } = useAuth();
  const [users, setUsers] = useState([]);
	const [queues, setQueues] = useState([]);
	const [connections, setConnections] = useState([]);
	const [tags, setTags] = useState([]);

	const getUsers = useCallback(async () => {
    if (isAuth) {
      try {
        const { data } = await api.get("/users/");
        setUsers(data.users);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isAuth]);

  const getQueues = useCallback(async () => {
    if (isAuth) {
      try {
        const { data } = await api.get("/queue");
        setQueues(data);
      } catch (err) {
        console.log(err);
      }
    }
  }, [isAuth]);

	const getConnections = useCallback(async () => {
		if (isAuth) {
			try {
				const { data } = await api.get("/whatsapp");
				setConnections(data);
			} catch (err) {
				console.log(err);
			}
		}
	}, [isAuth]);

	const getTags = useCallback(async () => {
		if (isAuth) {
			try {
				const { data } = await api.get("/tags");
				setTags(data.tags);
			} catch (err) {
				console.log(err);
			}
		}
	}, [isAuth]);

	useEffect(() => {
		getUsers();
		getQueues();
		getConnections();
		getTags();
  }, [isAuth]);

	return (
		<AuthContext.Provider
			value={{
				loading,
				user,
				isAuth,
				handleLogin,
				handleLogout,
				users,
				queues,
				connections,
				tags
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
