import React, { useEffect, useState } from "react";

import { makeStyles, Paper, Tab, Tabs } from "@material-ui/core";

import { toast } from "react-toastify";
import CompaniesManager from "../../components/CompaniesManager";
import HelpsManager from "../../components/HelpsManager";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import OnlyForSuperUser from "../../components/OnlyForSuperUser";
import PlansManager from "../../components/PlansManager";
import SchedulesForm from "../../components/SchedulesForm";
import Options from "../../components/Settings/Options";
import TabPanel from "../../components/TabPanel";
import Title from "../../components/Title";
import { useSettingsContext } from '../../context/Settings';
import useAuth from "../../hooks/useAuth";
import useCompanies from "../../hooks/useCompanies";
import { i18n } from "../../translate/i18n.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  mainPaper: {
    ...theme.scrollbarStyles,
    overflowY: "scroll",
    flex: 1,
  },
  tab: {
    backgroundColor: theme.mode === 'light' ? "#f2f2f2" : "#7f7f7f",
    borderRadius: 4,
  },
  paper: {
    ...theme.scrollbarStyles,
    overflowY: "scroll",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxHeight: "100%",
  },
  control: {
    padding: theme.spacing(1),
  },
  textfield: {
    width: "100%",
  },
}));

const SettingsCustom = () => {
  const classes = useStyles();
  const { settings, getSettingValue } = useSettingsContext();

  const [tab, setTab] = useState("options");
  const [schedules, setSchedules] = useState([]);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);

  const { getCurrentUserInfo } = useAuth();
  const { find, updateSchedules } = useCompanies();

  const handleTabChange = (event, newValue) => {
      async function findData() {
        setLoading(true);
        try {
          const companyId = localStorage.getItem("companyId");
          const company = await find(companyId);
          setCompany(company);
          setSchedules(company.schedules);

          const user = await getCurrentUserInfo();
          setCurrentUser(user);
        } catch (e) {
          toast.error(e);
        }
        setLoading(false);
      }
      findData();
      // eslint-disable-next-line react-hooks/exhaustive-deps

    setTab(newValue);
  };

  const handleSubmitSchedules = async (data) => {
    setLoading(true);
    try {
      setSchedules(data);
      await updateSchedules({ id: company.id, schedules: data });
      toast.success("Horários atualizados com sucesso.");
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const isSuper = () => {
    return currentUser.super;
  };

  useEffect(() => {
    async function findData() {
      setLoading(true);
      try {
        const companyId = localStorage.getItem("companyId");
        const company = await find(companyId);
        setCompany(company);
        setSchedules(company.schedules);

        const user = await getCurrentUserInfo();
        setCurrentUser(user);
      } catch (e) {
        toast.error(e);
      }
      setLoading(false);
    }
    findData();
  }, []);

  useEffect(() => {
    const scheduleTypeValue = getSettingValue('scheduleType');
    setSchedulesEnabled(scheduleTypeValue === "company");
  }, [getSettingValue]);

  return (
    <div id="settings-page">
      <MainContainer className={classes.root}>
        <MainHeader>
          <Title>{i18n.t("settings.title")}</Title>
        </MainHeader>
        <Paper className={classes.mainPaper} elevation={1}>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="on"
            variant="scrollable"
            onChange={handleTabChange}
            className={classes.tab}
          >
            <Tab label="Opções" value={"options"} />
            {schedulesEnabled && <Tab label="Horários" value={"schedules"} />}
            {isSuper() ? <Tab label="Empresas" value={"companies"} /> : null}
            {isSuper() ? <Tab label="Planos" value={"plans"} /> : null}
            {isSuper() ? <Tab label="Ajuda" value={"helps"} /> : null}
          </Tabs>
          <Paper className={classes.paper} elevation={0}>
            <TabPanel
              className={classes.container}
              value={tab}
              name={"schedules"}
            >
              <SchedulesForm
                loading={loading}
                onSubmit={handleSubmitSchedules}
                initialValues={schedules}
              />
            </TabPanel>
            <OnlyForSuperUser
              user={currentUser}
              yes={() => (
                <TabPanel
                  className={classes.container}
                  value={tab}
                  name={"companies"}
                >
                  <CompaniesManager />
                </TabPanel>
              )}
            />
            <OnlyForSuperUser
              user={currentUser}
              yes={() => (
                <TabPanel
                  className={classes.container}
                  value={tab}
                  name={"plans"}
                >
                  <PlansManager />
                </TabPanel>
              )}
            />
            <OnlyForSuperUser
              user={currentUser}
              yes={() => (
                <TabPanel
                  className={classes.container}
                  value={tab}
                  name={"helps"}
                >
                  <HelpsManager />
                </TabPanel>
              )}
            />
            <TabPanel className={classes.container} value={tab} name={"options"}>
              <Options
                settings={settings}
                scheduleTypeChanged={(value) =>
                  setSchedulesEnabled(value === "company")
                }
              />
            </TabPanel>
          </Paper>
        </Paper>
      </MainContainer>
    </div>
  );
};

export default SettingsCustom;
