import React, { useCallback, useEffect, useState } from 'react';

import Typography from "@material-ui/core/Typography";
import { Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { format } from 'date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';

import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import api from '../../services/api';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: true,
      text: 'Tickets',
      position: 'left',
    },
    datalabels: {
      display: true,
      anchor: 'start',
      offset: -30,
      align: "start",
      color: "#fff",
      textStrokeColor: "#000",
      textStrokeWidth: 2,
      font: {
        size: 20,
        weight: "bold"

      },
    }
  },
};

export const ChartsConnections = () => {
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [ticketsData, setTicketsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const total = ticketsData.reduce((acc, currentValue) => {
    return acc + Number(currentValue.quantidade);
  }, 0);

  const dataCharts = {
    labels: ticketsData && ticketsData?.length > 0 && ticketsData.map((item) => {
      if (item?.whatsapp?.name) {
        return item?.whatsapp?.name;
      }
      return 'Desconhecido';
    }),
    datasets: [
      {
        label: 'Atendimentos: ',
        data: ticketsData && ticketsData?.length > 0 && ticketsData.map((item) => item?.quantidade),
        backgroundColor: '#0972b9',
      },
    ],
  };

  const fetchTicketsInformation = useCallback(async () => {
    setLoading(true);
    try {
      const init = format(initialDate, 'yyyy-MM-dd');
      const end = format(finalDate, 'yyyy-MM-dd');
      const { data } = await api.get('/dashboard/connections', {
        params: {
          initialDate: init,
          finalDate: end,
        }
      });
      setTicketsData(data);
    } catch (error) {
      toast.error('Erro ao buscar informações dos tickets');
    } finally {
      setLoading(false);
    }
  }, [setLoading, setTicketsData, initialDate, finalDate]);

  useEffect(() => {
    fetchTicketsInformation();
  }, []);

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Total de atendimentos por conexão: {total}
      </Typography>

      <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ my: 2, }} >

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
          <DatePicker
            value={initialDate}
            onChange={(newValue) => { setInitialDate(newValue) }}
            label="Data inicial"
            renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
          <DatePicker
            value={finalDate}
            onChange={(newValue) => { setFinalDate(newValue) }}
            label="Data final"
            renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
          />
        </LocalizationProvider>

        <ButtonWithSpinner
          className="buttonHover"
          onClick={fetchTicketsInformation}
          loading={loading}
					variant="contained"
					color="primary"
        >
          Filtrar
        </ButtonWithSpinner>

      </Stack>
      <Bar options={options} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px', }} />
    </>
  );
}
