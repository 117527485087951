import React, { useEffect, useState } from "react";

import { Avatar, CardHeader, Tooltip } from "@material-ui/core";

import FacebookImg from "../../assets/icon-social/facebook.png";
import InstagramImg from "../../assets/icon-social/instagram.png";
import WppImg from "../../assets/icon-social/wpp.png";
import { i18n } from "../../translate/i18n";
import { useStyles } from './styles';

const TicketInfo = ({ contact, ticket, onClick }) => {
	const { user } = ticket
	const classes = useStyles();

	const [userName, setUserName] = useState('');
	const [contactName, setContactName] = useState('');

	useEffect(() => {
		if (contact) {
			setContactName(contact.name);
			if(document.body.offsetWidth < 600) {
				if (contact.name.length > 10) {
					const truncadName = contact.name.substring(0, 10) + '...';
					setContactName(truncadName);
				}
			}
		}

		if (user && contact) {
			setUserName(`${i18n.t("messagesList.header.assignedTo")} ${user.name}`);

			if(document.body.offsetWidth < 600) {
				setUserName(`${user.name}`);
			}
		}
	}, [contact, user, setContactName, setUserName]);

	return (
		<>
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" style={{ width: "70px", height: "70px"}} />}
				title={`${contactName} #${ticket.id}`}
				subheader={ticket.user && `${userName}`}
			/>
			<div className={classes.ticketChannelIconWrapper}>
				{ticket.channel === "whatsapp" && (
					<Tooltip title={`Chamou no WhatsApp`}>
						{/* <WhatsAppIcon fontSize="small" style={{ color: grey[700], marginRight: "5px" }} /> */}
						<img src={WppImg} alt="ícone do WhatsApp" width={26} height={26} />
					</Tooltip>
				)}
				{ticket.channel === "instagram" && (
					<Tooltip title={`Chamou via Instagram`}>
						{/* <InstagramIcon fontSize="small" style={{ color: grey[700], marginRight: "5px" }} /> */}
						<img src={InstagramImg} alt="ícone do Instagram" width={26} height={26} />
					</Tooltip>
				)}
				{ticket.channel === "facebook" && (
					<Tooltip title={`Chamado via Facebook`}>
						{/* <FacebookIcon fontSize="small" style={{ color: grey[700], marginRight: "5px" }} /> */}
						<img src={FacebookImg} alt="ícone do Facebook" width={26} height={26} />
					</Tooltip>
				)}
			</div>
		</>
	);
};

export default TicketInfo;
