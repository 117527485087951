import React from 'react';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import { X } from 'lucide-react';

const useStyles = makeStyles(theme => ({
  tag: {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '4px',
  },
}));

export default function ContactTag({ tag, icon, option, handleDelete, ...rest }) {
  const classes = useStyles();

  return (
    <Chip
      variant="outlined"
      classes={{ root: classes.tag }}
      style={{ borderColor: tag.color }}
      label={ tag.name }
      size="small"
      deleteIcon={handleDelete ? <X /> : null}
      onClick={handleDelete}
      {...rest}
    />
  );
};
