import React, { useEffect, useState } from "react";

import { FormControl, FormControlLabel, Grid, ListItemText, MenuItem, Select, Switch } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Facebook, Instagram, Visibility, WhatsApp } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { capitalize, isArray } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { envConfig } from '../../configs';
import { useAuthContext } from "../../context/Auth";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const ScheduleSchema = Yup.object().shape({
	body: Yup.string()
		.min(5, "Mensagem muito curta")
		.required("Obrigatório"),
	contactId: Yup.number().required("Obrigatório"),
	sendAt: Yup.string().required("Obrigatório")
});

const initialState = {
	body: "",
	contactId: "",
	sendAt: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
	sentAt: "",
	createTicket: false,
};

const initialContact = {
	id: "",
	name: ""
}

const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload, ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useAuthContext();

	const [whatsapps, setWhatsapps] = useState([]);
	const [schedule, setSchedule] = useState(initialState);
	const [currentContact, setCurrentContact] = useState(initialContact);
	const [contacts, setContacts] = useState([initialContact]);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
	const [file, setFile] = useState({
		url: "",
		data: undefined
	});


	const handleClose = () => {
		onClose();
		setSchedule(initialState);
		setFile({
			url: "",
			data: undefined
		})
	};

	const handleSaveSchedule = async values => {
		const scheduleData = { ...values, userId: user.id, whatsappId: selectedWhatsapp};
		const formData = new FormData();
		Object.keys(scheduleData).forEach(key => {
			formData.append(key, scheduleData[key]);
		});

		if (file.data) {
			formData.append('file', file.data);
		}

		try {
			if (scheduleId) {
				await api.put(`/schedules/${scheduleId}`, formData);
			} else {
				await api.post("/schedules", formData);
			}
			toast.success(i18n.t("scheduleModal.success"));
			if (typeof reload == 'function') {
				reload();
			}
			if (contactId) {
				if (typeof cleanContact === 'function') {
					cleanContact();
					history.push('/schedules');
				}
			}
		} catch (err) {
			toastError(err);
		}
		setCurrentContact(initialContact);
		setSchedule(initialState);
		handleClose();
	};

	const IconChannel = (channel) => {
		switch (channel) {
			case "facebook":
				return <Facebook style={{ color: "#3b5998", verticalAlign: "middle" }} />;
			case "instagram":
				return <Instagram style={{ color: "#e1306c", verticalAlign: "middle" }} />;
			case "whatsapp":
				return <WhatsApp style={{ color: "#25d366", verticalAlign: "middle" }} />
			default:
				return "error";
		}
	};

	useEffect(() => {
		if (ticket?.whatsappId) {
			setSelectedWhatsapp(ticket?.whatsappId);
		}
	}, [ticket]);

	useEffect(() => {
		if (!open) return;

		const fetchConnections = async () => {
			try {
				const { companyId } = user;
				const { data } = 	await api.get(`/whatsapp`, {
					params: { companyId, session: 0 },
				});
				setWhatsapps(data.filter(w =>  w.channel === 'whatsapp'));
				if (ticket) return;
				setSelectedWhatsapp(data?.find((w) => w.isDefault && w.channel === 'whatsapp')?.id || "");
			} catch (error) {
				console.log('error', error);
			}
		}

		fetchConnections();
	}, [open, ticket, user]);

	useEffect(() => {
		if (contactId && contacts.length) {
			const contact = contacts.find(c => c.id === contactId);
			if (contact) {
				setCurrentContact(contact);
			}
		}
	}, [contactId, contacts]);

	useEffect(() => {
		const { companyId } = user;
		if (open) {
			try {
				(async () => {
					const { data: contactList } = await api.get('/contacts/list', {
						params: { companyId: companyId }
					});
					const filteredContacts = contactList.filter((contact) => {
						return contact.channel === 'whatsapp';
					})
					const customList = filteredContacts.map((c) => ({ id: c.id, name: c.name }));
					if (isArray(customList)) {
						setContacts(customList);
					}
					if (contactId) {
						setSchedule(prevState => {
							return { ...prevState, contactId }
						});
					}

					if (!scheduleId) return;

					const { data } = await api.get(`/schedules/${scheduleId}`);
					setSchedule(prevState => {
						return { ...prevState, ...data, sendAt: moment(data.sendAt).format('YYYY-MM-DDTHH:mm') };
					});
					setFile({
						url: data.fileName ? `${envConfig.backendUrl}public/${data.fileName}` : '',
						data: undefined
					});
					setCurrentContact(data.contact);
				})()
			} catch (err) {
				toastError(err);
			}
		}
	}, [scheduleId, contactId, open, user]);

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{schedule.status === 'ERRO' ? 'Erro de Envio' : `Mensagem ${capitalize(schedule.status)}`}
				</DialogTitle>

				<Formik
					initialValues={schedule}
					enableReinitialize={true}
					validationSchema={ScheduleSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveSchedule(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										fullWidth
									>
										<Autocomplete
											fullWidth
											value={currentContact}
											options={contacts}
											onChange={(e, contact) => {
												const contactId = contact ? contact.id : '';
												setSchedule({ ...schedule, contactId });
												setCurrentContact(contact ? contact : initialContact);
											}}
											getOptionLabel={(option) => option.name}
											getOptionSelected={(option, value) => {
												return value.id === option.id
											}}
											renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contato" />}
										/>
									</FormControl>
								</div>
								<br />
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										rows={9}
										multiline={true}
										label={i18n.t("scheduleModal.form.body")}
										name="body"
										error={touched.body && Boolean(errors.body)}
										helperText={touched.body && errors.body}
										variant="outlined"
										margin="dense"
										fullWidth
									/>

								</div>
								<br />
								<div className={classes.multFieldLine}>
									<input
										type="file"
										variant="outlined"
										label="Anexar Arquivo"
										name="fileName"
										onChange={(e) => setFile({
											url: URL.createObjectURL(e.target.files[0]),
											data: e.target.files[0]
										})}
									/>
								</div>
								<br />

								{file.url && (
									<div className={classes.multFieldLine}>
										<Stack direction="row" spacing={1} alignItems="center">
											<Visibility
												style={{ cursor: 'pointer' }}
												onClick={() => window.open(file.url, '_blank')}
											/>
											<span>{file.data?.name || file.url.split('/').pop()}</span>
										</Stack>
									</div>
								)}

								<br />

								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("scheduleModal.form.sendAt")}
										type="datetime-local"
										name="sendAt"
										InputLabelProps={{
										  shrink: true,
										}}
										error={touched.sendAt && Boolean(errors.sendAt)}
										helperText={touched.sendAt && errors.sendAt}
										variant="outlined"
										fullWidth
									/>
								</div>
								<div className={classes.multFieldLine}>
									<Select
										required
										fullWidth
										displayEmpty
										variant="outlined"
										value={selectedWhatsapp}
										onChange={(e) => {
											setSelectedWhatsapp(e.target.value)
										}}
										MenuProps={{
											anchorOrigin: {
												vertical: "bottom",
												horizontal: "left",
											},
											transformOrigin: {
												vertical: "top",
												horizontal: "left",
											},
											getContentAnchorEl: null,
										}}
										renderValue={() => {
											if (selectedWhatsapp === "") {
												return "Selecione uma Conexão"
											}
											const whatsapp = whatsapps.find(w => w.id === selectedWhatsapp)
											return whatsapp?.name;
										}}
										disabled={ticket}
									>
										{whatsapps?.length > 0 &&
											whatsapps.map((whatsapp, key) => (
												<MenuItem dense key={key} value={whatsapp.id}>
													{IconChannel(whatsapp.channel)}
													<ListItemText style={{ marginLeft: 10 }} primary={whatsapp?.name} />
												</MenuItem>
											))
										}
									</Select>
								</div>

								<Grid item sm={3} style={{ alignItems: 'center', maxWidth: '100%' }}>
									<FormControlLabel
										style={{ alignItems: 'center' }}
										control={
											<Field
												as={Switch}
												color="primary"
												name="createTicket"
												checked={values.createTicket}
											/>
										}
										label={"Criar Ticket"}
									/>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("scheduleModal.buttons.cancel")}
								</Button>
								{ (schedule.sentAt === null || schedule.sentAt === "") && (
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{scheduleId
											? `${i18n.t("scheduleModal.buttons.okEdit")}`
											: `${i18n.t("scheduleModal.buttons.okAdd")}`}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								)}
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ScheduleModal;
