import React, {
  useEffect,
  useReducer,
  useState,
} from "react";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import ArrowUp from "@material-ui/icons/ArrowDropUp";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import TableRowSkeleton from "../../components/TableRowSkeleton";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import TicketFilters from "../../components/TicketFilters";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

// A custom hook that builds on useLocation to parse
// the query string for you.
const getUrlParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const reducer = (state, action) => {
  if (action.type === "LOAD_REPORTS") {
    const reports = action.payload.reports;
		const reset = action.payload.reset;

		if (reset) {
			return reports;
		}

    const newReports = [];

    reports.forEach((report) => {
      const reportIndex = state.findIndex((s) => s.id === report.id);
      if (reportIndex !== -1) {
        state[reportIndex] = report;
      } else {
        newReports.push(report);
      }
    });

    return [...state, ...newReports];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
		height: '500px',
  },
	arrowIcon: {
		position: 'absolute',
	},
	rowIcon: {
		position: 'absolute',
	  cursor: 'pointer',
	},
	rowCellSelected: {
		color: "#0872b9",
	}
}));

const initalFilters = {
	text: "",
	createdAtStart: "",
	createdAtEnd: "",
	finishedAtStart: "",
	finishedAtEnd: "",
	number: "",
	nameOfContact: "",
	users: [],
	connections: [],
	status: [],
	tags: [],
	queues: [],
}

const RenderArrow = ({ name, handleSelecteArrow }) => {
	const classes = useStyles();
	const [order, setOrder] = useState("DESC");

	const handleClick = () => {
		const newOrder = order === "ASC" ? "DESC" : "ASC";
		setOrder(newOrder);
		handleSelecteArrow({ name, type: newOrder });
	}

	return (
		<>
			{order === "ASC" ? (
				<ArrowUp 
					onClick={handleClick}
					className={classes.rowIcon}
				/>
			) : (
				<ArrowDown 
					onClick={handleClick}
					className={classes.rowIcon}
				/>
			)}
		
		</>	
	)
}

const Reports = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [reports, dispatch] = useReducer(reducer, []);
	const [count, setCount] = useState(0);
	const [filters, setFilters] = useState(initalFilters);
	const [selectedArrow, setSelectedArrow] = useState({
		name: "createdAt",
		type: "DESC",
	});
	const [loadingExport, setLoadingExport] = useState(false);

  const fetchFilters = async (reset = false) => {
    try {
      const { data } = await api.get("/reports", {
        params: { filters: JSON.stringify({ ...filters, pageNumber, order: { name: selectedArrow?.name, type: selectedArrow?.type } }) },
      });
      dispatch({ type: "LOAD_REPORTS", payload: { reports: data.reports, reset } });
			setCount(data.count);
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
			setLoading(false);
      toastError(err);
    }
  }

	const handleSelecteArrow = (name) => {
		setSelectedArrow(name);
		setPageNumber(1);
	}

	useEffect(() => {
		setLoading(true);
		fetchFilters(true);
	}, [selectedArrow]);

  useEffect(() => {
		setLoading(true);
		fetchFilters();
  }, [
    pageNumber,
  ]);

	const handleChangeFilters = ({target: { value, name }}) => {
		setFilters((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const resetFilters = () => {
		setFilters(initalFilters);
	}

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const truncate = (str, len) => {
		if (!str) {
			return '';
		}
    if (str.length > len) {
      return str.substring(0, len) + "...";
    }
    return str;
  };

	const handleExport = async () => {
		setLoadingExport(true);
		try {
			const { data } = await api.get("/reports/export", {
				params: { filters: JSON.stringify({ ...filters, order: { name: selectedArrow?.name, type: selectedArrow?.type } }) },
			});
			const csvData = data.reports.map((report) => ({
				id: report.id,
				contact: report?.ticket?.contact?.name || "",
				number: report?.ticket?.contact?.number || "",
				atendente: report?.user?.name || "",
				createdAt: report?.createdAt ? moment(report?.createdAt).format('DD/MM/YYYY HH:mm:ss'): '',
				startedAt: report?.startedAt ? moment(report?.startedAt).format('DD/MM/YYYY HH:mm:ss'): '',
				finishedAt: report?.finishedAt ? moment(report?.finishedAt).format('DD/MM/YYYY HH:mm:ss'): '',
				fila: report?.queue?.name || "",
				tags: report?.ticket?.tags?.map((tag) => tag.name).join(", ") || "",
				conexao: report?.whatsapp?.name || "",
			}));
			const headers = [
				{ label: "ID", key: "id" },
				{ label: "Contato", key: "contact" },
				{ label: "Número", key: "number" },
				{ label: "Atendente", key: "atendente" },
				{ label: "Criado", key: "createdAt" },
				{ label: "Inicio", key: "startedAt" },
				{ label: "Finalizado", key: "finishedAt" },
				{ label: "Fila", key: "fila" },
				{ label: "Tags", key: "tags" },
				{ label: "Conexão", key: "conexao" },
			];
			const csvReport = [headers, ...csvData];
			const csvReportData = csvReport.map((row) => row);
			const csvReportHeaders = csvReportData[0];
			const csvReportRows = csvReportData.slice(1);
			const csvReportHeadersString = csvReportHeaders.map((header) => header.label).join(",");
			const csvReportRowsString = csvReportRows.map(row => {
				return Object.values(row).map(value => `"${value}"`).join(",");
			}).join("\n");
			
			const csvReportString = `${csvReportHeadersString}\n${csvReportRowsString}`;
			const blob = new Blob([csvReportString], { type: "text/csv" });
			const url = window.URL.createObjectURL(blob);
	
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "report.csv");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
	
			setLoadingExport(false);
		} catch (err) {
			setLoadingExport(false);
			toastError(err);
		}
	}

  return (
    <MainContainer
			heightPersonalized="1500px"
		>
      <MainHeader>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Title>Relatórios</Title>
					<p
						style={{
							color: "#0872b9",
							marginTop: -5,
							fontSize: '1.2rem'
						}}
					>
						Total: {count}
					</p>
				</div>
        <MainHeaderButtonsWrapper>
          <Button
						variant="contained"
						color="primary"
						onClick={handleExport}
					>
						{loadingExport 
							?	<CircularProgress size={20} style={{ color: "#fff" }} /> 
							: 'Exportar Resultados'	
						}
					</Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

			<TicketFilters 
				filters={filters}
				handleChangeFilters={handleChangeFilters}
				resetFilters={resetFilters}
				fetchFilters={fetchFilters}
			/>

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell 
								align="center"
								name="id"
								className={selectedArrow?.name === "id" ? classes.rowCellSelected : ''}
							>
                id
								<RenderArrow  
									name="id"
									handleSelecteArrow={handleSelecteArrow}
								/>
              </TableCell>
							<TableCell 
								align="center"
								name="contact"
								className={selectedArrow?.name === "contact" ? classes.rowCellSelected : ''}
							>
                Contato
								<RenderArrow  
									name="contact"
									handleSelecteArrow={handleSelecteArrow}
								/>
              </TableCell>
							<TableCell 
								name="contact"
							>
                Número
              </TableCell>
							<TableCell 
								align="center"
								name="atendente"
								className={selectedArrow?.name === "atendente" ? classes.rowCellSelected : ''}
							>
                Atendente
								<RenderArrow  
									name="atendente"
									handleSelecteArrow={handleSelecteArrow}
								/>
              </TableCell>
							<TableCell 
								align="center"
								name="createdAt"
								className={selectedArrow?.name === "createdAt" ? classes.rowCellSelected : ''}
							>
                Criado
								<RenderArrow
									name="createdAt"
									handleSelecteArrow={handleSelecteArrow}
								/>
              </TableCell>
							<TableCell 
								align="center"
								name="startedAt"
								className={selectedArrow?.name === "startedAt" ? classes.rowCellSelected : ''}
							>
                Inicio
								<RenderArrow
									name="startedAt"
									handleSelecteArrow={handleSelecteArrow}
								/>
              </TableCell>
							<TableCell 
								align="center"
								name="finishedAt"
								className={selectedArrow?.name === "finalizedAt" ? classes.rowCellSelected : ''}
							>
                Finalizado
								<RenderArrow
									name="finishedAt"
									handleSelecteArrow={handleSelecteArrow}
								/>
              </TableCell>
							<TableCell 
								align="center"
							>
                Fila
              </TableCell>
							<TableCell 
								align="center"
							>
                Tags
              </TableCell>
							<TableCell 
								align="center"
							>
                Conexão
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {reports.map((report) => (
                <TableRow key={report.id}>
									<TableCell align="center">{report?.id}</TableCell>
									<TableCell align="center">{report?.ticket?.contact?.name}</TableCell>
									<TableCell align="center">{report?.ticket?.contact?.number}</TableCell>
									<TableCell align="center">{report?.user?.name}</TableCell>
									<TableCell align="center">{report?.createdAt ? moment(report?.createdAt).format('DD/MM/YYYY HH:mm:ss'): ''}</TableCell>
									<TableCell align="center">{report?.startedAt ? moment(report?.startedAt).format('DD/MM/YYYY HH:mm:ss'): ''}</TableCell>
									<TableCell align="center">{report?.finishedAt ? moment(report?.finishedAt).format('DD/MM/YYYY HH:mm:ss'): ''}</TableCell>
									<TableCell align="center">{report?.queue?.name}</TableCell>
									<TableCell align="center">{report?.ticket?.tags?.map((tag) => tag.name).join(", ")}</TableCell>
									<TableCell align="center">{report?.whatsapp?.name}</TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Reports;
