import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import { useStyles } from './styles';

function Loading(props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, props.className)} {...props}>
      <CircularProgress color="primary" />
    </div>
  )
}

export default Loading;
