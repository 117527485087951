import React from "react";

import { Autocomplete, Paper, Stack, TextField } from '@mui/material';
import { Wifi } from 'lucide-react';

function TicketsConnectionSelect({
	userWhatsapps,
	selectedWhatsapp = [],
	onChange,
}) {
  const isSelected = selectedWhatsapp.length > 0;

	const handleChange = (value) => {
		onChange(value);
	}

  return (
    <Stack flexDirection="row" gap="4px">
			<Wifi color={isSelected ? '#0032C0' : '#aaa'} size={24} />
      <Autocomplete
        multiple
        size="small"
				fullWidth
        options={userWhatsapps}
        value={selectedWhatsapp}
        onChange={(e, v) => handleChange(v)}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="Conexões" />}
        PaperComponent={({ children }) => <Paper elevation={10}>{children}</Paper>}
      />
    </Stack>
  );
}

export default TicketsConnectionSelect;
