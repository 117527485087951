import { useCallback, useEffect, useReducer, useState } from "react";

import { useAuthContext } from "../../context/Auth";
import toastError from "../../errors/toastError";
import { defaultReducer } from "../../reducers";
import api from "../../services/api";

function useSettings() {
  const [tags, dispatchTags] = useReducer(defaultReducer, []);
  const { isAuth } = useAuthContext();

  const [tagsLoading, setLoading] = useState(false);

  const fetchTags = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/tags/list`);
      dispatchTags({ type: "LOAD_DATA", payload: data });
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  const updateTag = useCallback(async (tag) => {}, []);

  useEffect(() => {
    if (isAuth) {
      fetchTags();
    }
  }, [isAuth, fetchTags]);

  return {
    tags,
    tagsLoading,
    updateTag
  };
}

export default useSettings;
