import React, { useContext, useEffect, useState } from "react";

import { FormControlLabel, IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { Colorize } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import { ColorBox } from "material-ui-color";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { AuthContext } from "../../context/Auth";
import toastError from "../../errors/toastError";
import { getRandomHexColor } from '../../helpers/getRandomHexColor';
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { useStyles } from './styles';

const TagSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório"),
});

const initialState = {
  name: "",
  color: getRandomHexColor(),
  kanban: 0,
  pixel: 0,
  pixelEvent: "",
  report: false,
  enableQuickSchedule: false,
  quickScheduleMessage: '',
  quickScheduleTimer: 1,
};

const TagModal = ({ open, onClose, tagId, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [showScheduleMessage, setShowScheduleMessage] = useState(false);
  const [quickScheduleTimer, setScheduleTimer] = useState(1);
  const [tag, setTag] = useState(initialState);

  const isUserWithPermissions = user.profile === "admin" || user.profile === "supervisor";

  const handleClose = () => {
    setTag(initialState);
    setColorPickerModalOpen(false);
    setShowTextField(false);
    setShowScheduleMessage(false);
    onClose();
  };

  const handleSaveTag = async (values) => {
    const tagData = { ...values, userId: user.id };

    if (tagData.enableQuickSchedule && !tagData.quickScheduleMessage) {
      return toast.error('É necessário a criação de uma mensagem para ser disparada!');
    }

    try {
      if (tagId) {
        await api.put(`/tags/${tagId}`, tagData);
      } else {
        await api.post("/tags", tagData);
      }
      toast.success(i18n.t("tagModal.success"));
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const handleKanbanChange = (e) => {
    const kanbanValue = e.target.checked ? 1 : 0;
    setTag((prev) => ({
      ...prev,
      kanban: kanbanValue,
    }));
  };

  const handlePixelChange = (e) => {
    const pixelValue = e.target.checked ? 1 : 0;
    setTag((prev) => ({
      ...prev,
      pixel: pixelValue,
    }));
    setShowTextField(e.target.checked); // Define o estado do campo de texto com base no estado do switch Pixel
  };

  const handleReportChange = (e) => {
    setTag((prev) => ({
      ...prev,
      report: e.target.checked,
    }));
  };

  const handleEnableQuickSchedule = (e) => {
    const isEnable = e.target.checked;

    setShowScheduleMessage(isEnable);
    setTag((prev) => ({
      ...prev,
      enableQuickSchedule: isEnable,
    }));
  }

  const handleQuickScheduleTimer = (e) => {
    const value = e.target.value;

    if (value !== '' && value <= 0) {
      setTag((prev) => ({
        ...prev,
        quickScheduleTimer: 1,
      }));
      return setScheduleTimer(1)
    };

    if (value === '') {
      setTag((prev) => ({
        ...prev,
        quickScheduleTimer: value,
      }));
      return setScheduleTimer(value);
    }

    setTag((prev) => ({
      ...prev,
      quickScheduleTimer: +value,
    }));

    return setScheduleTimer(+value);
  }

  useEffect(() => {
    try {
      (async () => {
        if (!tagId) return;

        const { data } = await api.get(`/tags/${tagId}`);

        setTag((prevState) => {
          return { ...prevState, ...data };
        });
        setShowTextField(data.pixel);
      })();
    } catch (err) {
      toastError(err);
    }
  }, [tagId, open]);

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {tagId
            ? `${i18n.t("tagModal.title.edit")}`
            : `${i18n.t("tagModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={tag}
          enableReinitialize={true}
          validationSchema={TagSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveTag(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t("tagModal.form.name")}
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    onChange={(e) =>
                      setTag((prev) => ({ ...prev, name: e.target.value }))
                    }
                    fullWidth
                  />
                </div>
                <br />
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    fullWidth
                    label={i18n.t("tagModal.form.color")}
                    name="color"
                    id="color"
                    error={touched.color && Boolean(errors.color)}
                    helperText={touched.color && errors.color}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div
                            style={{ backgroundColor: values.color }}
                            className={classes.colorAdorment}
                          ></div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <IconButton
                          size="small"
                          color="default"
                          onClick={() =>
                            setColorPickerModalOpen(!colorPickerModalOpen)
                          }
                        >
                          <Colorize />
                        </IconButton>
                      ),
                    }}
                    variant="outlined"
                    margin="dense"
                  />
                </div>

                <div className={classes.multFieldLine}>
                  {isUserWithPermissions && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tag.kanban === 1}
                            onChange={handleKanbanChange}
                            name="kanban"
                            color="primary"
                          />
                        }
                        label="Kanban"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tag.pixel === 1}
                            onChange={handlePixelChange}
                            name="Pixel"
                            color="primary"
                          />
                        }
                        label="Pixel"
                        labelPlacement="end"
                      />
                      {showTextField && (
                        <div className={classes.multFieldLine}>
                          <Field
                            as={TextField}
                            fullWidth
                            label="Evento"
                            name="pixelEvent"
                            id="pixelEvent"
                            variant="outlined"
                            margin="dense"
                          />
                        </div>
                      )}

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={tag.report}
                            onChange={handleReportChange}
                            name="report"
                            color="primary"
                          />
                        }
                        label="Relatório"
                        labelPlacement="end"
                      />
                    </>
                  )}

                  <FormControlLabel
                    label="Disparo automático"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        checked={tag.enableQuickSchedule}
                        onChange={handleEnableQuickSchedule}
                        name="enableQuickSchedule"
                        color="primary"
                      />
                    }
                  />
                </div>

                {(values.enableQuickSchedule || showScheduleMessage) && (
                  <>
                    <Field
                      as={TextField}
                      rows={9}
                      multiline={true}
                      label="Mensagem para disparar"
                      name="quickScheduleMessage"
                      variant="outlined"
                      onChange={(e) =>
                        setTag((prev) => ({
                          ...prev,
                          quickScheduleMessage: e.target.value
                        }))
                      }
                      margin="dense"
                      fullWidth
                    />

                    <Field
                      as={TextField}
                      value={quickScheduleTimer}
                      onChange={handleQuickScheduleTimer}
                      type="number"
                      label="Horas para enviar depois de adicionar tag"
                      name="quickScheduleTimer"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </>
                )}

                {colorPickerModalOpen && (
                  <div>
                    <ColorBox
                      disableAlpha={true}
                      hslGradient={false}
                      style={{ margin: "20px auto 0" }}
                      value={tag.color}
                      onChange={(val) => {
                        setTag((prev) => ({ ...prev, color: `#${val.hex}` }));
                      }}
                    />
                  </div>
                )}
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("tagModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {tagId
                    ? `${i18n.t("tagModal.buttons.okEdit")}`
                    : `${i18n.t("tagModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default TagModal;
