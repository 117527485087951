import React, { createContext, useContext } from "react";

import useTags from "../../hooks/useTags";

const TagsContext = createContext();

/**
 * @typedef {object} TagsContextReturn
 * @property {array<object>} tags
 * @property {boolean} tagsLoading
 * @property {function} updateTag
 *
 * @returns {TagsContextReturn}
 */

export function useTagsContext() {
  return useContext(TagsContext);
}

export function TagsProvider({ children }) {
  const tagsHook = useTags();

  return (
    <TagsContext.Provider value={{ ...tagsHook }}>
      {children}
    </TagsContext.Provider>
  );
}
