import React, { useState } from 'react';

import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';

import Loading from '../../../Loading';
import { checkBodyIsMediaUrl } from '../../helpers';
import QuotedMedia from '../QuotedMedia';
import ViewOnce from '../ViewOnce';
import { useStyles } from './styles';

function QuotedMessage({ quotedMsg, isFromMe, setPageNumber }) {
  const classes = useStyles();

  const [timeoutVar, setTimeoutVar] = useState(null);
  const [loading, setLoading] = useState(false);

  const scrollToQuoted = () => {
    if (timeoutVar) clearTimeout(timeoutVar);

    const isQuotedFromMe = quotedMsg.fromMe;
    const target = document.getElementById(quotedMsg.id);

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
      target.style.backgroundColor = isQuotedFromMe ? '#0084ff50' : '#0084ff20';

      setTimeoutVar(setTimeout(() => {
        target.style.backgroundColor = isQuotedFromMe ? '#0084ff' : '#f0f0f0';
      }, 3000));

      setLoading(false);
    } else {
      setLoading(true);
      setPageNumber(prev => prev + 1);
      setTimeout(() => scrollToQuoted(), 1000);
    }
  }

  return (
    <div
      onClick={scrollToQuoted}
      className={clsx(
        classes.quotedContainer,
        classes.quotedContainerLeft,
        { [classes.quotedContainerRight]: isFromMe },
      )}
    >
      <span
        className={clsx(
          classes.quotedSideColorLeft,
          { [classes.quotedSideColorRight]: quotedMsg.fromMe },
        )}
      />

      {loading && (
        <Loading
          className={clsx(
            classes.loading,
            { [classes.loadingRight]: isFromMe },
            { [classes.loadingLeft]: !isFromMe }
          )}
        />
      )}

      <div className={classes.quotedMsg}>
        <Typography className={classes.messageContactName}>
          {quotedMsg?.contact?.name}
        </Typography>

        <Stack>
          <QuotedMedia
            mediaType={quotedMsg.mediaType}
            mediaUrl={quotedMsg.mediaUrl}
            body={quotedMsg.body}
          />

          <Typography>
            {!checkBodyIsMediaUrl(quotedMsg) && quotedMsg?.body}
            {quotedMsg.mediaType === 'viewOnceMessageV2' && <ViewOnce disable />}
          </Typography>
        </Stack>
      </div>
    </div>
  );
}

export default QuotedMessage;
