import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useParams } from "react-router-dom";

import Ticket from "../../components/Ticket/";
import TicketsManagerTabs from "../../components/TicketsManagerTabs/";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		// backgroundColor: "#eee",
		// padding: theme.spacing(4),
		// height: `calc(100% - 48px)`,
		height: "100%",
		// overflowY: "hidden",
	},
	chatPapper: {
		display: "flex",
		height: "100%",
		backgroundColor: 'red',
	},

	gridContainer: {
		height: '100%',
	},

	contactsWrapper: {
		height: "100%",
	},

	messagesWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		backgroundColor: 'gray'
	},
	welcomeMsg: {
		background: theme.palette.total,
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
	},
}));

const TicketsCustom = () => {
	const classes = useStyles();
	const { ticketId } = useParams();

	return (
		<div className={classes.chatContainer} id="tickets-page">
			<Grid container spacing={0} className={classes.gridContainer}>
				<Grid item xs={4} className={classes.contactsWrapper}>
					<TicketsManagerTabs />
				</Grid>
				<Grid item xs={8} className={classes.messagesWrapper}>
					{ticketId ? (
						<>
							<Ticket />
						</>
					) : (
						<Paper square variant="outlined" className={classes.welcomeMsg}>
							<span>{i18n.t("chat.noTicketMessage")}</span>
						</Paper>
					)}
				</Grid>
			</Grid>
		</div>
	);
};

export default TicketsCustom;
