import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
    height: '100px',
    borderRadius: '8px',
    alignSelf: 'center'
  },
}));
