import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    borderRadius: 0,
    padding: 0,
    overflow: 'hidden',
  },

  rootContainer: {
    paddingLeft: '8px',
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: theme.palette.tabHeaderBackground,
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tabsContainer: {
    display: 'flex',
    marginBottom: '8px',
    borderBottomRightRadius: '8px',
    borderBottomLeftRadius: '8px',

    '& .indicator': {
      display: 'none',
    }
  },

  tab: {
    width: '33%',
    backgroundColor: '#0032c0',
    color: '#fff',
    minWidth: 'auto',

    '&.selected': {
      backgroundColor: '#fff',
      color: '#0032c0',
    }
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.optionsBackground,
    padding: "0 0 12px 8px",

    '& .iconButton': {
      position: "relative",
      border: '1px solid #ccc',
      borderRadius: '4px',
      height: '40px',
    }
  },

  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: 0,

    '& .iconButton': {
      position: "relative",
      border: '1px solid #ccc',
      borderRadius: '4px',
      height: '40px',
    }
  },

  filterIcons: {
    display: 'flex',
    gap: '12px',
  },

  filterSort: {
    border: '1px solid #ccc',
    color: '#aaa',
    borderRadius: '4px',
    minHeight: '40px',
    height: '40px',
    minWidth: '40px',
    width: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#AAA3',
    },
  },

  filterBadge: {
    right: '4px',
  },

  ticketsOptionContainer: {
    display: 'flex',

    '& .indicator': {
      display: 'none',
    },
  },

  ticketOption: {
    borderRadius: '4px',
    minWidth: 'auto',
    borderBottom: '1px solid #ccc',

    '&.selected': {
      backgroundColor: '#0032c0',
      color: '#fff',
      height: '46px',
      borderBottom: 'unset'
    }
  },

  searchInputWrapper: {
    flex: 1,
    background: theme.palette.total,
    display: "flex",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
    paddingBottom: '8px',
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },

  badge: {
		display: "flex",
		columnGap: "5px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none !important",
  },
}));
