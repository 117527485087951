import React from "react";

import { Menu, MenuItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
	select: {
		color: "blue",
		fontWeight: "bold",
	}
})


const FunnerModal = ({ anchorEl, handleClose, setPrefObject, prefObject, selectedTab }) => {
	const classes = useStyles();

	const handleUpdatePref = (pref) => {
		setPrefObject((e) => ({
			...e,
			[selectedTab]: pref
		}));;
		handleClose();
	}

	return (
		<Menu
		anchorEl={anchorEl}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "center",
			horizontal: "right",
		}}
		keepMounted
		transformOrigin={{
			vertical: "top",
			horizontal: "left",
		}}
		open={Boolean(anchorEl)}
		onClose={handleClose}
	>
		{/* <MenuItem
			onClick={() => handleUpdatePref("ALPHABETICAL_ASC")}
			className={prefObject[selectedTab] === "ALPHABETICAL_ASC" ? classes.select : ""}
		>
			Ordem alfabética crescente
		</MenuItem>
		<MenuItem
			onClick={() => handleUpdatePref("ALPHABETICAL_DESC")}
			className={prefObject[selectedTab] === "ALPHABETICAL_DESC" ? classes.select : ""}
		>
			Ordem alfabética decrescente
		</MenuItem> */}
		<MenuItem
			onClick={() => handleUpdatePref("CREATION_ASC")}
			className={prefObject[selectedTab] === "CREATION_ASC" ? classes.select : ""}
		>
			Ordem de criação crescente
		</MenuItem>
		<MenuItem
			onClick={() => handleUpdatePref("CREATION_DESC")}
			className={prefObject[selectedTab] === "CREATION_DESC" ? classes.select : ""}
		>
			Ordem de criação decrescente
		</MenuItem>
		<MenuItem
			onClick={() => handleUpdatePref("UPDATE_ASC")}
			className={prefObject[selectedTab] === "UPDATE_ASC" ? classes.select : ""}
		>
			Ordem de atualização crescente
		</MenuItem>
		<MenuItem
			onClick={() => handleUpdatePref("UPDATE_DESC")}
			className={prefObject[selectedTab] === "UPDATE_DESC" ? classes.select : ""}
		>
			Ordem de atualização decrescente
		</MenuItem>
	</Menu>
	)
}

export default FunnerModal;
