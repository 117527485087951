import React, { useState } from 'react';

import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { MoreVert, Replay } from '@material-ui/icons';
import { Stack } from '@mui/material';
import clsx from 'clsx';
import { Check, Undo } from 'lucide-react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAuthContext } from '../../context/Auth';
import { useTicketsContext } from '../../context/Tickets/TicketsContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ButtonWithSpinner from '../ButtonWithSpinner';
import TicketOptionsMenu from '../TicketOptionsMenu';
import TicketsQueueSelect from '../TicketsQueueSelect';
import { useStyles } from './styles';

function TicketActionButtonsCustom({ ticket }) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuthContext();
  const { setCurrentTicket } = useTicketsContext();

  const userOneQueue = user.queues?.length === 1;
  const queue = userOneQueue ? user.queues[0] : null;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState(queue);
  const [loading, setLoading] = useState(false);

  const ticketOptionsMenuOpen = Boolean(anchorEl);

  const handleOpenTicketOptionsMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseTicketOptionsMenu = e => {
    setAnchorEl(null);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      const isStatusClosed = ticket.status === 'closed';

      if (isStatusClosed && !selectedQueue) {
        return toast.error('Por favor, selecione uma fila.');
      }

      const { data } = await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        queueId: isStatusClosed ? selectedQueue?.id : undefined,
      });

      if (status === 'open') {
        if (data.status === 'open' && data.userId !== user.id) {
          toast.error('Ticket já foi aceito por outro usuário!');
          return history.push('/tickets');
        }
        setCurrentTicket(prevTicket => ({ ...prevTicket, ...data, code: '#open' }));
      } else {
        setCurrentTicket(null);
        history.push('/tickets');
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.actionButtons}>
      {ticket.status === 'closed' && (
        <Stack flexDirection="row">
          <TicketsQueueSelect
            selectedQueues={selectedQueue}
            userQueues={user?.queues}
            onChange={values => setSelectedQueue(values)}
          />
          <ButtonWithSpinner
            loading={loading}
            startIcon={<Replay />}
            size="small"
            onClick={e => handleUpdateTicketStatus(e, 'open', user?.id)}
          >
            {i18n.t('messagesList.header.buttons.reopen')}
          </ButtonWithSpinner>
        </Stack>
      )}
      {ticket.status === 'open' && (
        <>
          <Tooltip title={i18n.t('messagesList.header.buttons.return')}>
            <IconButton onClick={e => handleUpdateTicketStatus(e, 'pending', null)} className={classes.iconButton}>
              <Undo />
            </IconButton>
          </Tooltip>

          <Tooltip title={i18n.t('messagesList.header.buttons.resolve')}>
            <IconButton
              onClick={e => handleUpdateTicketStatus(e, 'closed', user?.id)}
              className={clsx(classes.iconButton, classes.checkButton)}
            >
              <Check />
            </IconButton>
          </Tooltip>

          <IconButton onClick={handleOpenTicketOptionsMenu} className={classes.iconButton}>
            <MoreVert />
          </IconButton>

          <TicketOptionsMenu
            ticket={ticket}
            anchorEl={anchorEl}
            menuOpen={ticketOptionsMenuOpen}
            handleClose={handleCloseTicketOptionsMenu}
          />
        </>
      )}
      {ticket.status === 'pending' && (
        <ButtonWithSpinner
          loading={loading}
          size="small"
          variant="contained"
          color="primary"
          onClick={e => handleUpdateTicketStatus(e, 'open', user?.id)}
        >
          {i18n.t('messagesList.header.buttons.accept')}
        </ButtonWithSpinner>
      )}
    </div>
  );
}

export default TicketActionButtonsCustom;
