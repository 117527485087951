import React from "react";

import { Autocomplete, Paper, Stack, TextField } from '@mui/material';
import { Tags } from 'lucide-react';

import { useTagsContext } from '../../context/Tags';

function TicketsTagsSelect({
	selectedTags = [],
	onChange,
}) {
	const { tags } = useTagsContext();

  const isSelected = selectedTags.length > 0;

	const handleChange = (value) => {
		onChange(value);
	}

  return (
    <Stack flexDirection="row" gap="4px" >
			<Tags color={isSelected ? '#0032C0' : '#aaa'} size={24} />
      <Autocomplete
        multiple
        size="small"
        fullWidth
        options={tags}
        value={selectedTags}
        onChange={(e, v) => handleChange(v)}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="Tags" />}
        PaperComponent={({ children }) => <Paper elevation={10}>{children}</Paper>}
      />
    </Stack>
  );
}

export default TicketsTagsSelect;
