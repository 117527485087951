import React, {
  useContext,
  useEffect,
  useState
} from "react";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import { CircularProgress, DialogActions, DialogContent } from "@material-ui/core";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import { AuthContext } from "../../context/Auth";
import toastError from "../../errors/toastError";
import api from "../../services/api";


const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
	btnWrapper: {
		marginRight: theme.spacing(2),
	},
	contentRoot: {
    display: "flex",
    flexDirection: "column"
  },
	column: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    padding: theme.spacing(2),
  },
	divider: {
    margin: theme.spacing(0, 2),
  },
}));

const initialState = {
	body: "",
};

const MessageBlocks = () => {
  const classes = useStyles();

	const { users } = useContext(AuthContext);
	const [messagesSendByUser, setMessagesSendByUser] = useState([]);
	const [messageBlock, setMessageBlock] = useState(initialState);
	const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/messages-block", {
				});

				setMessageBlock(data || {
					body: ""
				})

			} catch (err) {
				toastError(err);
			}
		})()
	}, []);

	useEffect(() => {
		(async () => {
			const { data } = await api.get('/messages-user-block', {
				params: {
					initialDate: initialDate,
					finalDate: finalDate,
				}
			})

			setMessagesSendByUser(data);
		})()
	}, [initialDate, finalDate])

	const handleSaveMessageBlocked = async values => {
		try {
			await api.post("/messages-block", {
				messageBlocked: values.body,
			});

			toast.success("Palavras Proibidas Salvas com Sucesso!");
		} catch (err) {
			toastError(err);
		}
	};

	const parserUser = (userId) => {
		const user = users.find(user => user.id === userId);

		return user ? user.name : "";
	}

  return (
    <MainContainer>
      <MainHeader>
        <Title>Moderação</Title>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
      >
				<Formik
					initialValues={messageBlock}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveMessageBlocked(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent>
								<i>Voce pode adicionar quantas palavras quiser, separadas por vírgula.</i>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										rows={9}
										multiline={true}
										label='Mensagens/Palavras proibidas'
										name="body"
										error={touched.body && Boolean(errors.body)}
										helperText={touched.body && errors.body}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
								</div>
							</DialogContent>

							<DialogActions>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									Salvar
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>

				<DialogContent className={classes.contentRoot}>
					<Title>Notificações</Title>
					<h3
						style={{
							marginTop: '-10px',
						}}
					>
						Tentativas de envio de palavras proibidas
					</h3>
					<br />
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginBottom: 10,
							columnGap: 10,
						}}
					>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
							<DatePicker
								value={initialDate}
								onChange={(newValue) => { setInitialDate(newValue) }}
								label="Data inicial"
								renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}

							/>
						</LocalizationProvider>

						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
							<DatePicker
								value={finalDate}
								onChange={(newValue) => { setFinalDate(newValue) }}
								label="Data final"
								renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
							/>
						</LocalizationProvider>
					</div>

				<Table size="small">
          <TableHead>
						<TableRow>
							<TableCell>Usuário</TableCell>
							<TableCell>Mensagem</TableCell>
							<TableCell>Contato</TableCell>
							<TableCell>Data da Tentativa</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{messagesSendByUser.map((message) => (
							<TableRow key={message.id}>
								<TableCell>{parserUser(message.userId)}</TableCell>
								<TableCell>{message.body}</TableCell>
								<TableCell>{message.contact?.name}</TableCell>
								<TableCell>{moment(message.createdAt).format('DD/MM/YYYY HH:mm:ss')}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>

				</DialogContent>

      </Paper>
    </MainContainer>
  );
};

export default MessageBlocks;
